import { default as indexzxILiPIY7lMeta } from "/builds/bazinga/publica/frontend/pages/[...slug]/index.vue?macro=true";
import { default as _91uuid_93T5FvXU26OVMeta } from "/builds/bazinga/publica/frontend/pages/blokkli/library-item/[uuid].vue?macro=true";
import { default as indexsl9fNE4FkJMeta } from "/builds/bazinga/publica/frontend/pages/choose-employer/index.vue?macro=true";
import { default as _91id_93pVj7N6kplpMeta } from "/builds/bazinga/publica/frontend/pages/documents/[id].vue?macro=true";
import { default as indexwHPEM30El3Meta } from "/builds/bazinga/publica/frontend/pages/index.vue?macro=true";
import { default as _91id_93Uj93HoCbm8Meta } from "/builds/bazinga/publica/frontend/pages/media/[id].vue?macro=true";
import { default as icons_45overviewz3fscrlHfgMeta } from "/builds/bazinga/publica/frontend/pages/nuxt-admin/icons-overview.vue?macro=true";
import { default as indexEHEpHXWBsfMeta } from "/builds/bazinga/publica/frontend/pages/search/index.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexzxILiPIY7lMeta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "blokkli-library-item-uuid",
    path: "/blokkli/library-item/:uuid()",
    meta: _91uuid_93T5FvXU26OVMeta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/blokkli/library-item/[uuid].vue")
  },
  {
    name: "choose-employer",
    path: "/choose-employer",
    meta: indexsl9fNE4FkJMeta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/choose-employer/index.vue")
  },
  {
    name: "media-canonical-document",
    path: "/(dokumente|documents|documenti)/:slug(.*)*",
    meta: _91id_93pVj7N6kplpMeta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/documents/[id].vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexwHPEM30El3Meta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/index.vue")
  },
  {
    name: "media-canonical",
    path: "/media/:id",
    meta: _91id_93Uj93HoCbm8Meta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/media/[id].vue")
  },
  {
    name: "nuxt-admin-icons-overview",
    path: "/nuxt-admin/icons-overview",
    meta: icons_45overviewz3fscrlHfgMeta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/nuxt-admin/icons-overview.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexEHEpHXWBsfMeta || {},
    component: () => import("/builds/bazinga/publica/frontend/pages/search/index.vue")
  }
]