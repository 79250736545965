import type {
  VisitorStateEmployerFragment,
  VisitorStatePensionFundFragment,
} from '#build/graphql-operations'
import { PensionFundSection } from '~/settings'
import type { LinkItem } from '~/types/global'

const getTitle = (key: PensionFundSection, $texts: any): string => {
  switch (key) {
    case PensionFundSection.LegalBasis:
      return $texts('legalBasis', 'Legal basis')
    case PensionFundSection.SavingsAndPension:
      return $texts(
        'savingsAndPension',
        'Savings contributions and pension plans',
      )
    case PensionFundSection.InterestRatesKeyFigures:
      return $texts('interestRatesKeyFigures', 'Interest rates and key figures')
    case PensionFundSection.ParityCommission:
      return $texts('parityCommission', 'Parity commission')
  }
}

const getDescription = (key: PensionFundSection, $texts: any): string => {
  switch (key) {
    case PensionFundSection.LegalBasis:
      return $texts('legalBasisDescription')
    case PensionFundSection.SavingsAndPension:
      return $texts('savingsAndPensionDescription')
    case PensionFundSection.InterestRatesKeyFigures:
      return $texts('interestRatesKeyFiguresDescription')
    case PensionFundSection.ParityCommission:
      return $texts('parityCommissionDescription')
  }
}

/**
 * Generates a list of links for the pension fund and employer page.
 *
 * The employerUrl is optional, so that links can be generated for the
 * employer page where we don't want to link to itself.
 */
function getPensionFundLinks(t: any, pensionFundUrl = ''): LinkItem[] {
  if (!pensionFundUrl) {
    return []
  }

  return Object.values(PensionFundSection).map((key) => {
    return {
      title: getTitle(key, t),
      to: pensionFundUrl + '#' + key,
      description: getDescription(key, t),
    }
  })
}

type UseVisitorState = {
  setEmployerId: (id?: string) => Promise<void>
  employerUrl: ComputedRef<string | undefined>
  employerLinks: ComputedRef<LinkItem[]>
  employerId: ComputedRef<string | undefined>
  hasSelectedEmployer: ComputedRef<boolean>
  pensionFund: ComputedRef<VisitorStatePensionFundFragment | null>
  employer: ComputedRef<VisitorStateEmployerFragment | null>
  load: () => Promise<void>
}

export default function (): UseVisitorState {
  const selectedEmployerId = useCookie('selectedEmployerId', {
    default: () => '',
    decode: (v) => v,
  })
  const employerData = useState<VisitorStateEmployerFragment | null>(
    'employer_data',
  )

  const load = (): Promise<void> => {
    if (!selectedEmployerId.value) {
      employerData.value = null
      return Promise.resolve()
    }

    if (import.meta.server) {
      return Promise.resolve()
    }

    return useGraphqlQuery('visitorState', {
      id: selectedEmployerId.value,
    }).then((data) => {
      if (
        data &&
        data.data &&
        data.data.entityById?.__typename === 'NodeEmployer'
      ) {
        employerData.value = data.data.entityById || null
      } else {
        employerData.value = null
        selectedEmployerId.value = ''
      }
    })
  }

  const setEmployerId = (id?: string): Promise<void> => {
    selectedEmployerId.value = id || ''
    return load()
  }

  const pensionFund = computed<VisitorStatePensionFundFragment | null>(
    () => employerData.value?.pensionFund || null,
  )

  const { $texts } = useEasyTexts()

  const employerLinks = computed<LinkItem[]>(() => {
    const url = pensionFund.value?.url?.path
    if (!url) {
      return []
    }
    return getPensionFundLinks($texts, url)
  })

  const employerUrl = computed(() => employerData.value?.url?.path)

  const hasSelectedEmployer = computed<boolean>(
    () => !!(selectedEmployerId.value && employerData.value),
  )

  const employer = computed(() => {
    return employerData.value
  })

  const employerId = computed(() => selectedEmployerId.value)

  return {
    setEmployerId,
    employerUrl,
    employerLinks,
    hasSelectedEmployer,
    pensionFund,
    employer,
    load,
    employerId,
  }
}
