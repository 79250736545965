<template>
  <div class="bk-main-canvas !bg-gray-50">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const language = useCurrentLanguage()

useHead({
  htmlAttrs: {
    lang: language.value,
  },
})
</script>

<style lang="postcss">
.bk-blokkli-item-options-text {
  min-width: 140px;
  input {
    color-scheme: dark;
  }
}
</style>
