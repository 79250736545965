import { defineNuxtPlugin } from '#app'

export type ObservedClientRect = {
  id: string
  rect: DOMRect
  isVisible: boolean
}

/**
 * Global intersection observer.
 */
export default defineNuxtPlugin({
  name: 'client-rects',
  setup() {
    let observer: IntersectionObserver | null = null
    const rects = useState<Record<string, ObservedClientRect | null>>(
      'client_rects',
      () => {
        return {}
      },
    )
    if (import.meta.client) {
      function observerCallback(entries: IntersectionObserverEntry[]) {
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i]
          const id = entry.target.id
          if (id) {
            rects.value[id] = {
              id,
              rect: entry.boundingClientRect,
              isVisible: entry.isIntersecting,
            }
          }
        }
      }
      observer = new IntersectionObserver(observerCallback, {
        threshold: 0.35,
      })
    }

    return {
      provide: {
        clientRects: {
          register: (id: string) => {
            if (observer) {
              const element = document.getElementById(id)
              if (element) {
                observer.observe(element)
              }
            }
          },
          unregister: (id: string) => {
            if (!observer) {
              return
            }
            const element = document.getElementById(id)
            if (element) {
              observer.unobserve(element)
            }
            rects.value[id] = null
          },
        },
      },
    }
  },
})
