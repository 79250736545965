import { defineNuxtPlugin } from 'nuxt/app'
import type { FetchResponse } from 'ofetch'
import type {
  DrupalMessage,
  DrupalMessageType,
} from '~/composables/useDrupalMessages'

interface GraphqlResponseWithMessage {
  data: {
    messengerMessages?: Array<{
      type: DrupalMessageType
      message: string
      escaped: string
      safe: string
    }>
  }
}

/**
 * Try to extract the messages from a GraphQL query or mutation.
 */
function extractMessages(
  response: FetchResponse<GraphqlResponseWithMessage>,
): DrupalMessage[] {
  if (
    response._data &&
    typeof response._data === 'object' &&
    response._data.data?.messengerMessages &&
    Array.isArray(response._data.data?.messengerMessages)
  ) {
    return response._data.data.messengerMessages.map((v) => {
      return {
        type: v.type,
        message: v.safe,
      }
    })
  }

  return []
}

/**
 * This is only called when performing a query or mutation from within the nuxt
 * app (e.g. not via custom server routes).
 */
export default defineNuxtPlugin({
  name: 'graphql-fetch-options',
  setup(app) {
    const state = useGraphqlState(app)
    const language = useCurrentLanguage()
    const negotiated = useRequestEvent()?.context.__language_context
    const { messages } = useDrupalMessages()

    state.fetchOptions = {
      onRequest({ options }: any) {
        try {
          if (!options.params) {
            options.params = {}
          }

          // Workaround until fixes in nuxt-language-negotiation.
          options.params.__language_context = negotiated || language.value

          if (!options.headers) {
            options.headers = {}
          }

          // Pass the cookie header from the client to the server.
          options.headers = useRequestHeaders()

          return options
        } catch (e) {
          console.error('Exception in onRequest handler:', e)
        }
      },
      onResponse(result: any) {
        // Extract drupal messages from every GraphQL response.
        extractMessages(result.response).forEach((v) => {
          const exists = messages.value.find((m) => m.message === v.message)
          if (!exists) {
            messages.value.push(v)
          }
        })
      },
    }
  },
})
