import { defineNuxtPlugin } from '#app'

/**
 * Loads data about the current session.
 */
export default defineNuxtPlugin({
  name: 'visitor-state',
  async setup() {
    const { load } = useVisitorState()
    await load()
  },
})
